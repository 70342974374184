import React, { Suspense, lazy, useEffect, useState } from 'react';
import { /*Switch,*/ Route, Routes, /*Redirect*/ Navigate } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));

//Rutas y componentes del sistema

////login
const ProtectedRoute = lazy(() => import('./ProtectedRoutes'));
const CompLogin = lazy(() => import('./login/Login'));
const CompLoginSDQuito = lazy(() => import('./login/LoginSDQuito'));
///// Registro
const CompRegistroSDQuito = lazy(() => import('./registro/registroSDQuito'));
///// Activar Usuario
const CompActivarUsuarioSDQ = lazy(() => import('./registro/activarUsuarioSDQuito'));
const CompActivarUsuario = lazy(() => import('./registro/activarUsuario'));
///// Recuperación de contraseña
const IngresarCorreo = lazy(() => import('./recuperarPass/IngresarCorreo'));
const IngresarNueva = lazy(() => import('./recuperarPass/IngresarNueva'));
///// Configuraciones de Usuario
const CompConfiguracion = lazy(() => import('./shared/configuracion'));

////empresas
const SeleccionEmpresa = lazy(() => import('./dashboard/SeleccionEmpresa.js'));
const CompDatosEmpresa = lazy(() => import('./empresaVistaUsuario/datosEmpresa.js'));
const CompEdicionEmpresa = lazy(() => import('./empresa/edicionEmpresa.js'));
const CompEmpresas = lazy(() => import('./empresa/empresas.js'));
const CompCrearEmpresa = lazy(()=>import('./empresa/crearEmpresa.js'))
const CompUsuarios1 = lazy(()=>import('./usuarios/usuariosVistaAdminEmpresa/mostrarUsuarios.js'))
const CompCrearUsuario1 = lazy(()=>import('./usuarios/usuariosVistaAdminEmpresa/crearUsuarios.js'))
const CompEdicionUsuario1 = lazy(()=>import('./usuarios/usuariosVistaAdminEmpresa/edicionUsuarios.js'))

//Agencias
const CompAgencias = lazy(()=>import('./agencias/agencias.js'))
const CompCrearAgencias = lazy(()=>import('./agencias/crearAgencia.js'))
const CompEditarAgencias = lazy(()=>import('./agencias/editarAgencia'))
const CompAgenciasUsuario = lazy(()=>import('./agencias/verAgenciasUsuario'))
const CompVisualizarAgenciaInfo = lazy(()=>import('./agencias/visualizarAgenciaInfo'))

//puntos de emision
const CompPuntosEmision = lazy(()=>import('./agencias/puntosEmision.js'))
const CompCrearPuntosEmision = lazy(()=>import('./agencias/crearPuntosEmision.js'))
const CompEditarPuntosEmision = lazy(()=>import('./agencias/editarPuntosEmision.js'))

//empresas vista usuario
const CompVerDatosEmpresa = lazy(()=>import('./empresaVistaUsuario/verDatosEmpresa.js'))

////productos
const CompMostrarProductos = lazy(() => import('./productosDebito/mostrarProductos'));
const CompCrearProducto = lazy(() => import('./productosDebito/crearProducto'));
const CompEdicionProducto = lazy(() => import('./productosDebito/edicionProducto'));
const CompMediosPagoComisiones = lazy(() => import('./productosDebito/mediosPagoComisiones'));

/////Productos facturacion
const CompMostrarProductosFacturacion = lazy(() => import('./productosFacturacion/mostrarProductos.js'));
const CompCrearProductoFacturacion = lazy(() => import('./productosFacturacion/crearProducto.js')); 
const CompEdicionProductoFacturacion = lazy(() => import('./productosFacturacion/edicionProducto.js'));

////clientes
const CompClientes = lazy(() => import('./clientes/mostrarClientes'));
const CompCrearCliente = lazy(() => import('./clientes/crearCliente'));
const CompEdicionCliente = lazy(() => import('./clientes/edicionCliente'));

/// afiliaciones - certificados
const CompCertificados = lazy(() => import('./certificados/mostrarCertificados'));
const CompAfiliacionIndividual = lazy(() => import('./certificados/afiliacionIndividual'));
const CompCrearCertificado = lazy(() => import('./certificados/crearCertificado'));
const CompAfiliacionMasiva = lazy(() => import('./certificados/afiliacionMasiva'));
const CompEditarCertificado = lazy(() => import('./certificados/editarCertificado'));
const CompConsultaAfiliaciones = lazy(() => import('./certificados/consultarAfiliaciones.js'));
const CompInfoAfiliacion = lazy(() => import('./certificados/infoAfiliaciones.js'));
const CompRenovarCertificado = lazy(() => import('./certificados/renovarCertificado.js'));

//facturacion comprobantes
const CompFacturar = lazy(() => import('./facturacion/ventas.js'));
const CompNuevoComprobanteVentas = lazy(() => import('./facturacion/nuevoComprobanteVentas.js'));
const CompFirmaElectronicaEmpresa = lazy(() => import('./facturacion/firmaElectronica.js'));

//cobranzas cuotas
const CompPrecuotasCuotasDebito = lazy(() => import('./cobranzas/precuotasCuotasDebito.js'));
const CompAdministrarPrecuotas = lazy(() => import('./cobranzas/administrarPrecuotas.js'));
const CompAdministrarCuotasActivas = lazy(() => import('./cobranzas/administrarCuotasActivas.js'));
const CompAdministrarCuotasAnuladas = lazy(() => import('./cobranzas/administrarCuotasAnuladas.js'));
const CompUltimaNomina = lazy(() => import('./cobranzas/ultimaNomina.js'));

//cobranzas
const CompGestionDebitosBancarios = lazy(() => import('./cobranzas/gestionDebitos'));
const CompGenerarLotesCobro = lazy(() => import('./cobranzas/generacionLotesCobro'));
const CompCuotasLote = lazy(() => import('./cobranzas/cuotasLoteCobro'));
const CompRespuestaCobro = lazy(() => import('./cobranzas/respuestasCobro'));

//liquidaciones
const CompLiquidacionesProductos = lazy(() => import('./liquidaciones/liquidacionesProductos'));
const CompLiquidacionesCuotas = lazy(() => import('./liquidaciones/liquidacionesCuotas.js'));

//contabilidad
const CompEstadosCuenta = lazy(() => import('./contabilidad/estadosCuenta.js'));
const CompListadoEstadosCuenta = lazy(() => import('./contabilidad/listadoEstadosCuenta.js'));

////usuarios
const CompUsuarios = lazy(() => import('./usuarios/mostrarUsuarios'));
const CompCrearUsuario = lazy(() => import('./usuarios/crearUsuarios'));
const CompEdicionUsuario = lazy(() => import('./usuarios/edicionUsuarios'));

//nomina departamentos cargos
const CompNomina = lazy(() => import('./nomina/nomina.js'));
const CompCrearNomina = lazy(() => import('./nomina/crearNomina.js'));
const CompEditarNomina = lazy(() => import('./nomina/editarNomina.js'));
const CompDepartamentosCargos = lazy(() => import('./nomina/departamentosCargos.js'));
const CompCrearDepartamento = lazy(() => import('./nomina/crearDepartamento.js'));
const CompEditarDepartamento = lazy(() => import('./nomina/editarDepartamento.js'));

//administracion
const CompEmisor = lazy(() => import('./administracion/emisores'));
const CompServidoresCorreo = lazy(() => import('./correos/servidoresCorreo'));
const CompMostrarRolesPermisos = lazy(() => import('./rolesPermisos/mostrarRolesPermisos.js'));
const CompCrearRol = lazy(() => import('./rolesPermisos/crearRol.js'));
const CompCrearPermiso = lazy(() => import('./rolesPermisos/crearPermiso.js'));

//Correos
const CompRemitentes = lazy(() => import('./correos/remitentes'));
const CompCrearRemitente = lazy(() => import('./correos/crearRemitente'));
const CompEditarRemitente = lazy(() => import('./correos/editarRemitente'));
const CompPlantillas = lazy(() => import('./correos/plantillasCorreo'));
const CompCampaigns = lazy(() => import('./correos/campaigns.js'));
const CompCrearCampaign = lazy(() => import('./correos/crearCampaign.js'));
const CompCorreosInformacion = lazy(() => import('./correos/listaCorreosInformacion.js'));

//Formulario de Afiliacion
const CompFormularioSocios = lazy(() => import('./socios/formulariodeafiliacion'));

//SDQUITO
const InicioSDQuito = lazy(() => import('./sdquito/InicioSDQuito'));
const CompListaBoletos = lazy(() => import('./sdquito/ListaBoletos'));
const CompIngresarBoleto = lazy(() => import('./sdquito/IngresarBoleto'));
const Error404sdq = lazy(() => import('./sdquito/error-pages/Error404'));
const IngresarNuevaSDQ = lazy(() => import('./sdquito/recuperarPass/IngresarNueva'));
const IngresarCorreoSDQ = lazy(() => import('./sdquito/recuperarPass/IngresarCorreo'));
//Actualizar Datos
const CompActualizarDatosSDQ = lazy(() => import('./sdquito/ActualizarDatos'));


const AppRoutes = (props) => {
    
      const logeado=props.logeado
      var ambiente=props.ambiente

      switch (ambiente) {
        case 'sdquito':

          return(
            <Suspense fallback={<Spinner/>}>
           
          <Routes>
  
  {/* rutas del sistema del quito */}
            <Route element={<ProtectedRoute logeado={logeado}/>}>
              <Route path="/sdquito-inicio" element={ <InicioSDQuito></InicioSDQuito> } />
              <Route path="/boletos/listaBoletos" element={ <CompListaBoletos></CompListaBoletos> } />
              <Route path="/boletos/ingresarBoleto" element={ <CompIngresarBoleto></CompIngresarBoleto> } />
              <Route path="/actualizarDatos" element={ <CompActualizarDatosSDQ></CompActualizarDatosSDQ> } />
              <Route path="*" element={ <Error404sdq></Error404sdq> } />
              <Route path="/" element={<Navigate to="/sdquito-inicio" />} />
            </Route>
  
            <Route exact path="/sdquito-registro" element={<CompRegistroSDQuito></CompRegistroSDQuito>}/>
            <Route exact path="/sdquito-login" element={<CompLoginSDQuito></CompLoginSDQuito>}/>
            <Route exact path="/login" element={<CompLogin></CompLogin>}/>
            <Route exact path="/recuperar-contrasenia-sdq" element={<IngresarCorreoSDQ></IngresarCorreoSDQ>}/>
            <Route exact path="/nueva-contrasenia-sdq/:token" element={<IngresarNuevaSDQ></IngresarNuevaSDQ>}/>
            <Route exact path="/activarUsuario/:token" element={<CompActivarUsuario></CompActivarUsuario>}/>
            <Route exact path="/activarUsuarioSDQ/:token" element={<CompActivarUsuarioSDQ></CompActivarUsuarioSDQ>}/>
            
          </Routes>
            
        </Suspense>
          )
  
          break;

        case 'sistema':
          
        return (
          <Suspense fallback={<Spinner/>}>
           
          <Routes>
            
            <Route path="/basic-ui/buttons" element={ <Buttons></Buttons> } />: 
            <Route path="/basic-ui/dropdowns" element={ <Dropdowns></Dropdowns> } />
            <Route path="/basic-ui/typography" element={ <Typography></Typography> } />
            <Route path="/form-Elements/basic-elements" element={ <BasicElements></BasicElements> } />
            <Route path="/tables/basic-table" element={ <BasicTable></BasicTable> } />
            <Route path="/icons/mdi" element={ <Mdi></Mdi> } />
            <Route path="/charts/chart-js" element={ <ChartJs></ChartJs> } />
            <Route path="/user-pages/login-1" element={ <Login></Login> } />
            <Route path="/user-pages/register-1" element={ <Register1></Register1> } />
            <Route path="/error-pages/error-404" element={ <Error404></Error404> } />
            <Route path="/error-pages/error-500" element={ <Error500></Error500> } />
  
          {/* rutas del sistema */}
            <Route element={<ProtectedRoute logeado={logeado}/>}>
              <Route path="/configuracionUsuario" element={ <CompConfiguracion></CompConfiguracion> } />
              <Route path="/dashboard" element={ <Dashboard></Dashboard> } />
              
              <Route path="/seleccionEmpresaAgencia" element={ <SeleccionEmpresa></SeleccionEmpresa> } />
              <Route path="/empresa/datosEmpresa" element={<CompDatosEmpresa></CompDatosEmpresa>} />
              <Route path="/empresa/edicionEmpresa/:id" element={ <CompEdicionEmpresa></CompEdicionEmpresa> } />

              <Route path="/empresa/agencias/:id" element={ <CompAgencias></CompAgencias> } />
              <Route path="/empresa/crearAgencia/:id" element={ <CompCrearAgencias></CompCrearAgencias> } />
              <Route path="/empresa/editarAgencia/:id" element={ <CompEditarAgencias></CompEditarAgencias> } />

              <Route path="/empresa/verAgencias" element={ <CompAgenciasUsuario></CompAgenciasUsuario> } />
              <Route path="/empresa/visualizarAgenciaInfo/:id" element={ <CompVisualizarAgenciaInfo></CompVisualizarAgenciaInfo>}/>
              
              <Route path="/empresa/agencia/puntosEmision/:id" element={ <CompPuntosEmision></CompPuntosEmision>}/>
              <Route path="/empresa/agencia/crearPuntoEmision/:id" element={ <CompCrearPuntosEmision></CompCrearPuntosEmision>}/>
              <Route path="/empresa/agencia/editarPuntoEmision/:id" element={ <CompEditarPuntosEmision></CompEditarPuntosEmision>}/>

              <Route path="/empresa/verEmpresa/:id" element={ <CompVerDatosEmpresa></CompVerDatosEmpresa> } />

              <Route path="/empresa/nominaEmpresa" element={ <CompNomina></CompNomina> } />
              <Route path="/empresa/nuevoEmpleado" element={ <CompCrearNomina></CompCrearNomina> } />
              <Route path="/empresa/editarEmpleado/:id" element={ <CompEditarNomina></CompEditarNomina> } />
              <Route path="/empresa/departamentosCargos" element={ <CompDepartamentosCargos></CompDepartamentosCargos> } />
              <Route path="/empresa/nuevoDepartamento" element={ <CompCrearDepartamento></CompCrearDepartamento> } />
              <Route path="/empresa/editarDepartamento/:id" element={ <CompEditarDepartamento></CompEditarDepartamento> } />
              <Route path="/empresa/usuarios" element={ <CompUsuarios1></CompUsuarios1> } />
              <Route path="/empresa/crearUsuario" element={ <CompCrearUsuario1></CompCrearUsuario1> } />
              <Route path="/empresa/editarUsuario/:id" element={ <CompEdicionUsuario1></CompEdicionUsuario1> } />

              <Route path="/productos/mostrarProductos" element={ <CompMostrarProductos></CompMostrarProductos> } />
              <Route path="/productos/crearProducto" element={ <CompCrearProducto></CompCrearProducto> } />
              <Route path="/productos/editarProducto/:id" element={<CompEdicionProducto></CompEdicionProducto>} />
              <Route path="/productos/mediosPagoComisiones/:id" element={<CompMediosPagoComisiones></CompMediosPagoComisiones>} />
              
              <Route path="/productosFacturacion/mostrarProductos" element={<CompMostrarProductosFacturacion></CompMostrarProductosFacturacion>} />
              <Route path="/productosFacturacion/crearProducto" element={<CompCrearProductoFacturacion></CompCrearProductoFacturacion>} />
              <Route path="/productosFacturacion/editarProducto/:id" element={<CompEdicionProductoFacturacion></CompEdicionProductoFacturacion>} />
              
              <Route path="/clientes/clientes" element={ <CompClientes></CompClientes>  } />
              <Route path="/clientes/crearCliente" element={ <CompCrearCliente></CompCrearCliente> } />
              <Route path="/clientes/editarCliente/:id" element={ <CompEdicionCliente></CompEdicionCliente> } />
              
              <Route path="/clientes/certificados" element={ <CompCertificados></CompCertificados>} />
              <Route path="/afiliaciones/afiliacionIndividual" element={<CompAfiliacionIndividual></CompAfiliacionIndividual>} />
              <Route path="/afiliaciones/crearCertificado/:id" element={<CompCrearCertificado></CompCrearCertificado>} />
              <Route path="/afiliaciones/afiliacionMasiva" element={<CompAfiliacionMasiva></CompAfiliacionMasiva>} />
              <Route path="/certificados/editarCertificado/:id" element={<CompEditarCertificado></CompEditarCertificado>} />
              <Route path="/afiliaciones/consultarAfiliaciones" element={<CompConsultaAfiliaciones></CompConsultaAfiliaciones>} />
              <Route path="/afiliaciones/infoAfiliaciones/:id" element={<CompInfoAfiliacion></CompInfoAfiliacion>} />
              <Route path="/afiliaciones/renovarAfiliaciones/:id" element={<CompRenovarCertificado></CompRenovarCertificado>} />
              
              <Route path="/facturacion/ventas" element={<CompFacturar></CompFacturar>} />
              <Route path="/facturacion/nuevoComprobanteVentas" element={<CompNuevoComprobanteVentas></CompNuevoComprobanteVentas>} />
              <Route path="/facturacion/firmaElectronicaEmpresa" element={<CompFirmaElectronicaEmpresa></CompFirmaElectronicaEmpresa>} />

              <Route path="/cobranzas/gestionDebitos" element={<CompGestionDebitosBancarios></CompGestionDebitosBancarios>} />
              <Route path="/cobranzas/lotesCobroProducto/:id" element={<CompGenerarLotesCobro></CompGenerarLotesCobro>} />
              <Route path="/cobranzas/cuotas-lotesCobro/:id" element={<CompCuotasLote></CompCuotasLote>} />
              <Route path="/cobranzas/respuestasLotesCobro/:id" element={<CompRespuestaCobro></CompRespuestaCobro>} />
              
              <Route path="/cobranzas/precuotas-cuotas-debito" element={<CompPrecuotasCuotasDebito></CompPrecuotasCuotasDebito>} />
              <Route path="/cobranzas/precuotas/:id" element={<CompAdministrarPrecuotas></CompAdministrarPrecuotas>} />
              <Route path="/cobranzas/cuotasActivas/:id" element={<CompAdministrarCuotasActivas></CompAdministrarCuotasActivas>} />
              <Route path="/cobranzas/cuotasAnuladas/:id" element={<CompAdministrarCuotasAnuladas></CompAdministrarCuotasAnuladas>} />
              <Route path="/cobranzas/ultimaNomina" element={<CompUltimaNomina></CompUltimaNomina>} />
              
              <Route path="/cobranzas/liquidacionesProductos" element={<CompLiquidacionesProductos></CompLiquidacionesProductos>} />
              <Route path="/cobranzas/liquidacionesCuotas/:id" element={<CompLiquidacionesCuotas></CompLiquidacionesCuotas>} />

              <Route path="/contabilidad/estadosCuenta" element={<CompEstadosCuenta></CompEstadosCuenta>} />
              <Route path="/contabilidad/listadoEstadosCuenta/:id" element={<CompListadoEstadosCuenta></CompListadoEstadosCuenta>} />

              <Route path="/administracion/afiliadas" element={ <CompEmpresas></CompEmpresas> } />
              <Route path="/empresa/crearEmpresaAfiliada" element={ <CompCrearEmpresa></CompCrearEmpresa> } />
              <Route path="/administracion/usuarios" element={ <CompUsuarios></CompUsuarios> } />
              <Route path="/administracion/crearUsuario" element={ <CompCrearUsuario></CompCrearUsuario> } />
              <Route path="/administracion/editarUsuario/:id" element={ <CompEdicionUsuario></CompEdicionUsuario> } />
              <Route path="/administracion/emisores" element={ <CompEmisor></CompEmisor> } />
              <Route path="/administracion/servidoresCorreo" element={ <CompServidoresCorreo></CompServidoresCorreo> } />
              
              <Route path="/administracion/rolesPermisos" element={ <CompMostrarRolesPermisos></CompMostrarRolesPermisos> } />
              <Route path="/administracion/crearRol" element={ <CompCrearRol></CompCrearRol> } />
              <Route path="/administracion/crearPermiso" element={ <CompCrearPermiso></CompCrearPermiso> } />
              
              <Route path="/correos/remitentes" element={ <CompRemitentes></CompRemitentes> } />
              <Route path="/correos/crearRemitente" element={ <CompCrearRemitente></CompCrearRemitente>} />
              <Route path="/correos/editarRemitente/:id" element={<CompEditarRemitente></CompEditarRemitente>} />
              <Route path="/correos/plantillas" element={<CompPlantillas></CompPlantillas>} />
              <Route path="/correos/campaigns" element={<CompCampaigns></CompCampaigns>} />
              <Route path="/correos/crearCampaign" element={<CompCrearCampaign></CompCrearCampaign>} />
              <Route path="/correos/correosInformacion/:id" element={<CompCorreosInformacion></CompCorreosInformacion>} />

              <Route path="/" element={<Navigate to="/dashboard" />} />
            </Route>

            <Route exact path="/sdquito-registro" element={<CompRegistroSDQuito></CompRegistroSDQuito>}/>
            <Route exact path="/sdquito-login" element={<CompLoginSDQuito></CompLoginSDQuito>}/>
            <Route exact path="/login" element={<CompLogin logeado={logeado}></CompLogin>}/>
            <Route exact path="/recuperar-contrasenia" element={<IngresarCorreo></IngresarCorreo>}/>
            <Route exact path="/nueva-contrasenia/:token" element={<IngresarNueva></IngresarNueva>}/>
            
            <Route exact path="/form-afiliacion/:club" element={<CompFormularioSocios></CompFormularioSocios>}/>
            
            <Route exact path="/recuperar-contrasenia-sdq" element={<IngresarCorreoSDQ></IngresarCorreoSDQ>}/>
            <Route exact path="/nueva-contrasenia-sdq/:token" element={<IngresarNuevaSDQ></IngresarNuevaSDQ>}/>
            <Route exact path="/activarUsuario/:token" element={<CompActivarUsuario></CompActivarUsuario>}/>
            <Route exact path="/activarUsuarioSDQ/:token" element={<CompActivarUsuarioSDQ></CompActivarUsuarioSDQ>}/>
  
            <Route path="*" element={ <Error404></Error404> } />
          </Routes>
            
        </Suspense>
        )

        break;
      }    
}

export default AppRoutes;